import { Button, Icon, Input, Modal, Radio, SageClassnames, Textarea } from '@kajabi/sage-react';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import EmailInput from 'apps/commerce/common/checkout/inputs/EmailInput';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/datepicker.scss';
import { da, de, es, fi, fr, hu, it, ja, nl, nn, pl, pt, ru, sv, tr } from 'date-fns/locale';
import { getSiteCountryCode } from 'apps/commerce/popup_checkout/util';

const registerSiteLocale = () => {
  switch (getSiteCountryCode()) {
    case 'da':
      registerLocale('da', da);
      break;
    case 'de':
      registerLocale('de', de);
      break;
    case 'es':
      registerLocale('es', es);
      break;
    case 'fi':
      registerLocale('fi', fi);
      break;
    case 'fr':
      registerLocale('fr', fr);
      break;
    case 'hu':
      registerLocale('hu', hu);
      break;
    case 'it':
      registerLocale('it', it);
      break;
    case 'ja':
      registerLocale('ja', ja);
      break;
    case 'nl':
      registerLocale('nl', nl);
      break;
    case 'nn':
      registerLocale('nn', nn);
      break;
    case 'pl':
      registerLocale('pl', pl);
      break;
    case 'pt':
      registerLocale('pt', pt);
      break;
    case 'ru':
      registerLocale('ru', ru);
      break;
    case 'sv':
      registerLocale('sv', sv);
      break;
    case 'tr':
      registerLocale('tr', tr);
      break;
    default:
      break;
  }
};

const GIFT_MESSAGE_MAX_LENGTH = 500;
const PURCHASER_NAME_MAX_LENGTH = 75;
const RECIPIENT_EMAIL_FIELD = 'giftDetails.recipientEmail';
const PURCHASER_NAME_FIELD = 'giftDetails.purchaserName';
const MESSAGE_FIELD = 'giftDetails.message';
const GIFT_DELIVERY_DATE_FIELD = 'giftDetails.giftDeliveryDate';
const MINIMUM_DELIVERY_DATE = moment().add(1, 'days').toDate();
const MAXIMUM_DELIVERY_DATE = moment().add(6, 'months').toDate();

const GiftModal = ({ onCancel, onContinue }) => {
  const { t } = useTranslation();

  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const purchaserName = watch(PURCHASER_NAME_FIELD);
  const message = watch(MESSAGE_FIELD);
  const recipientEmail = watch(RECIPIENT_EMAIL_FIELD);
  useEffect(() => {
    registerSiteLocale();
    setDefaultLocale(getSiteCountryCode());
    if (!message) setValue(MESSAGE_FIELD, t('gift_offers.message'));
  }, []);

  const giftDeliveryDate = watch(GIFT_DELIVERY_DATE_FIELD);

  // this is a workaround for mobile to make it so the date
  // picker doesn't open the keyboard when focused
  const datePickerRef = useRef(null);
  useEffect(() => {
    if (datePickerRef.current !== null) {
      datePickerRef.current.input.readOnly = true;
    }
  }, [datePickerRef]);

  return (
    <>
      <Modal.Header className="gift-modal__header">
        <Button
          className="gift-modal__back-button"
          color={Button.COLORS.SECONDARY}
          onClick={onCancel}
          iconOnly={true}
          icon="caret-left"
        />
      </Modal.Header>
      <div className="gift-modal__body">
        <div className="gift-modal__form">
          <Modal.Body>
            <div className="gift-modal">
              <fieldset className="gift-modal__fieldset">
                <legend className="visually-hidden">Gift options</legend>
                <EmailInput
                  label={t('gift_offers.recipient_email')}
                  emailField={RECIPIENT_EMAIL_FIELD}
                  defaultValue={recipientEmail}
                  validateOnBlur={true}
                  placeholder="gift_offers.recipient_email_placeholder"
                />
                <Input
                  className="purchaserName gift-purchaser-name"
                  inputType="text"
                  label={t('gift_offers.from')}
                  name="purchaserName"
                  id="purchaserName"
                  value={purchaserName}
                  onChange={(event) => {
                    const trimmedValue = event.target.value.substring(0, PURCHASER_NAME_MAX_LENGTH);
                    setValue(PURCHASER_NAME_FIELD, trimmedValue, { shouldValidate: true });
                  }}
                  placeholder={t('gift_offers.your_name')}
                  message={`${purchaserName?.length || 0}/${PURCHASER_NAME_MAX_LENGTH}`}
                />
                <Textarea
                  id="giftMessage"
                  className="gift-message-textarea"
                  data-testid="giftMessage"
                  message={`${message?.length || 0}/${GIFT_MESSAGE_MAX_LENGTH}`}
                  label={t('gift_offers.gift_message')}
                  placeholder={t('gift_offers.message')}
                  value={message}
                  onChange={(event) => {
                    const trimmedValue = event.target.value.substring(0, GIFT_MESSAGE_MAX_LENGTH);
                    setValue(MESSAGE_FIELD, trimmedValue, { shouldValidate: true });
                  }}
                  onBlur={(event) => {
                    if (!event.target.value) setValue(MESSAGE_FIELD, t('gift_offers.message'));
                  }}
                  style={{ resize: 'vertical' }}
                />
                <h5
                  className={`${SageClassnames.TYPE.HEADING_6} ${SageClassnames.SPACERS.SM_TOP} ${SageClassnames.SPACERS.XS_BOTTOM}`}
                >
                  {t('gift_offers.delivery_date')}
                </h5>
                <Radio
                  customContent={<p>{t('gift_offers.immediately')}</p>}
                  id="send_now_radio"
                  label={t('gift_offers.send_now')}
                  name="send_now"
                  value="send_now"
                  checked={!giftDeliveryDate}
                  onClick={() => setValue(GIFT_DELIVERY_DATE_FIELD, undefined)}
                />
                <Radio
                  customContent={<p>{t('gift_offers.scheduled')}</p>}
                  id="schedule_later_radio"
                  label={t('gift_offers.schedule_for_later')}
                  name="schedule_for_later"
                  value="schedule_for_later"
                  checked={!!giftDeliveryDate}
                  onClick={() => {
                    setValue(GIFT_DELIVERY_DATE_FIELD, MINIMUM_DELIVERY_DATE);
                    datePickerRef.current.setOpen(true);
                  }}
                />
                {/* use of 'visually-hidden' rather than conditionally rendering this element so that the */}
                {/* datePickerRef.current is defined at first render, if you don't do this, the datepicker */}
                {/* will cause the keyboard to open on mobile */}
                <div className={`datepicker-wrapper ${giftDeliveryDate ? '' : 'visually-hidden'}`}>
                  <DatePicker
                    ref={datePickerRef}
                    onChange={(date) => setValue(GIFT_DELIVERY_DATE_FIELD, date)}
                    selected={giftDeliveryDate ? moment(giftDeliveryDate).toDate() : null}
                    className="datepicker-input sage-form-field sage-input__field "
                    allowSameDay={false}
                    minDate={MINIMUM_DELIVERY_DATE}
                    maxDate={MAXIMUM_DELIVERY_DATE}
                    showPopperArrow={false}
                    popperPlacement="top-start"
                    onKeyDown={(e) => {
                      // this prevents manual entering of dates and forces
                      // the use of the picker
                      e.preventDefault();
                    }}
                  />
                  {giftDeliveryDate && (
                    <Icon icon={Icon.ICONS.CALENDAR_SIMPLE} className="datepicker-inset-icon" />
                  )}
                </div>
              </fieldset>
            </div>
          </Modal.Body>
        </div>
      </div>
      <Modal.Footer className="gift-modal__footer">
        <Button
          data-testid="continue-button"
          color={Button.COLORS.SECONDARY}
          onClick={onCancel}
          className="checkout-modal__continue-btn"
        >
          {t('gift_offers.cancel')}
        </Button>
        <Button
          data-testid="continue-button"
          color={Button.COLORS.PRIMARY}
          onClick={onContinue}
          disabled={
            !purchaserName ||
            !recipientEmail ||
            (errors && Object.keys(errors).includes('giftDetails'))
          }
          className="checkout-modal__continue-btn"
        >
          {t('gift_offers.continue')}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default GiftModal;

GiftModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};
